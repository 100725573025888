var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.closeSearch,
          expression: "closeSearch"
        }
      ],
      staticClass: "search"
    },
    [
      _c(
        "div",
        { staticClass: "search__activator" },
        [
          _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function(ref) {
                    var on = ref.on
                    return [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            attrs: { icon: "", tile: "" },
                            on: { click: _vm.toggleSearch }
                          },
                          on
                        ),
                        [
                          _c(
                            "transition",
                            [
                              !_vm.showSearcher
                                ? _c(
                                    "v-icon",
                                    { attrs: { size: "24", color: "primary" } },
                                    [_vm._v("mdi-magnify")]
                                  )
                                : _c(
                                    "v-icon",
                                    { attrs: { size: "24", color: "primary" } },
                                    [_vm._v("mdi-close")]
                                  )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            },
            [
              !_vm.showSearcher
                ? _c("span", [_vm._v(_vm._s(_vm.translations.search))])
                : _c("span", [_vm._v(_vm._s(_vm.translations.close))])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "search__searcher",
          class: { "search__searcher--opened": _vm.showSearcher }
        },
        [
          _c(
            "div",
            { staticClass: "search__searcher-container" },
            [
              _c("v-text-field", {
                ref: "searchfield",
                attrs: {
                  label: _vm.translations.search,
                  "hide-details": "",
                  solo: "",
                  dense: ""
                },
                on: { keyup: _vm.searchAuto },
                model: {
                  value: _vm.search,
                  callback: function($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              })
            ],
            1
          )
        ]
      ),
      _c(
        "div",
        {
          staticClass: "search__content",
          class: { "search__content--opened": _vm.showContent }
        },
        [
          _c(
            "div",
            { staticClass: "search__scroll" },
            [
              _vm._l(_vm.gamesToShow, function(game) {
                return _c("CardGameSearch", {
                  key: game.id,
                  staticClass: "ma-2",
                  attrs: {
                    title: game.name,
                    subtitle: game.type,
                    image: game.image,
                    gameId: game.vg,
                    mode: game.mode
                  }
                })
              }),
              _vm.gamesToShow && _vm.gamesToShow.length === 0
                ? _c("div", { staticClass: "search__empty" }, [
                    _vm._v(_vm._s(_vm.translations.searchEmpty))
                  ])
                : _vm._e()
            ],
            2
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }