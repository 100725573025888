<template lang="pug">
v-carousel.banner(
  v-if="showOnDesktop"
  height="250"
  cycle
  interval="6000"
  :show-arrows="false"
  hide-delimiter-background)
  v-carousel-item(
    v-for="(item, i) in bannersD",
    :key="i",
    :src="item.imageD"
  )
    v-sheet.banner__sheet(height="100%", tile)
      a.banner__link(:href="item.url")
        span.text-h4 {{ item.title }}
        span.text-h5 {{ item.description }}
v-carousel.banner(
  v-else-if="showOnMobile"
  height="250"
  cycle
  interval="6000"
  :show-arrows="false"
  hide-delimiter-background)
  v-carousel-item(
    v-for="(item, i) in bannersM",
    :key="i",
    :src="item.imageM"
  )
    v-sheet.banner__sheet(height="100%", tile)
      a.banner__link(:href="item.url")
        span.text-h4 {{ item.title }}
        span.text-h5 {{ item.description }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Banner',
  props: {},
  data: () => ({
    items: null,
  }),
  computed: {
    ...mapState(['bannersD', 'bannersM']),
    isLower750(){
      return this.$vuetify.breakpoint.width < 750
    },
    showOnDesktop(){
      return this.bannersD !== undefined && this.bannersD.length > 0 && !this.isLower750
    },
    showOnMobile(){
      return this.bannersM !== undefined && this.bannersM.length > 0 && !this.isLower750
    },
  },
}
</script>

<style lang="sass">
@import '@/styles/_variables.sass'
.banner
  & &__sheet.v-sheet
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    background-color: rgba(#424242, 0.3)
  & &__link
    color: #fff
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    text-decoration: none
    &:hover, &:focus
      color: $primary
      text-decoration: underline
</style>