<template lang="pug">
v-container.navbar.py-0
  .navbar__grid(v-if="$vuetify.breakpoint.smAndDown")
    //- .navbar__links
    //-   v-app-bar-nav-icon(@click.stop="$emit('togglemenu')")
    v-img.navbar__logo(
      @click="goToHome"
      :src="logo",
      alt="Emara play",
      position="center center",
      contain)
    .navbar__user
      template(v-if="balance")
        .navbar__balance 
          <div>
            <div>{{ $t('components.NavbarLogin.balance') }}</div>
            <span>{{ balance.result.balance }} <span v-html="currencySymbol(balance.result.currency)"></span></span>
          </div>
      template(v-else)
        NavbarLogin
      ButtonLanguage

  .navbar__grid(v-else)
    v-img.navbar__logo(
      @click="goToHome"
      :src="logo",
      alt="Emara play",
      position="left center",
      contain
    )
    .navbar__links
    //-   router-link.navbar__link(:to="{ name: 'Home', params: { lobbyId: lobbyId }}") Home
    //-   router-link.navbar__link(:to="{ name: 'AllGames', params: { lobbyId: lobbyId }}") Juegos
    .navbar__user
      template(v-if="balance")
        .navbar__balance 
          <div>
            <div>{{ $t('components.NavbarLogin.balance') }}</div>
            <span>{{ balance.result.balance }} <span v-html="currencySymbol(balance.result.currency)"></span></span>
          </div>
      template(v-else)
        NavbarLogin
      ButtonLanguage
</template>

<script>
import logo from "@/assets/logo_emara.png";
import NavbarLogin from "@/components/NavbarLogin";
import ButtonLanguage from "@/components/ButtonLanguage";

export default {
  name: "Navbar",
  components: {
    NavbarLogin,
    ButtonLanguage,
  },
  props: {
    user: String,
    balance: {
      type: Object,
    },
  },
  data: () => ({
    logo,
    lobbyId: undefined,
  }),
  created() {
    this.lobbyId = this.$route.params.lobbyId
  },
  methods: {
    goToHome() {
      if(this.$router.currentRoute.name !== 'Home') {
        this.$router.push({ name: 'Home', params: { lobbyId: this.lobbyId }})
      }
    },
  }
};
</script>

<style lang="sass">
@import '@/styles/_variables.sass'

.navbar
  &__grid
    display: grid
    grid-template-columns: auto minmax(120px, 150px) auto
    @media (min-width: 960px)
      grid-template-columns: minmax(120px, 150px) auto auto
  &__links
    display: flex
    align-items: center
  & &__link
    text-decoration: none
    padding: 0 .5rem
    color: $font-base
    &:hover, &:focus
      text-decoration: underline
      color: $primary
    // .router-link-exact-active
  & &__logo
    max-height: 48px
    cursor: pointer
  &__user
    display: flex
    justify-content: flex-end
    align-items: center
  &__balance
    color: $primary
    font-size: 1.2rem
    @media (max-width: 748px)
      font-size: 0.8rem
    font-weight: 700
    margin: 0 1rem 0 0
</style>