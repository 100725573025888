export const componentsEn = {
  NavbarLogin: {
    username: 'Username',
    password: 'Password',
    buttonSend: 'Access',
    balance: 'Balance',
  },
  GroupCategories: {
    AllGames: 'Games',
  },
  Search: {
    search: 'Search',
    close: 'Close',
    searchEmpty: 'Not games found',
  },
  Games: {
    play: 'Play',
  },
}