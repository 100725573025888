var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    { attrs: { dark: "" } },
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            "elevate-on-scroll": "",
            fixed: "",
            app: "",
            color: "blue-grey darken-4"
          }
        },
        [
          _c("Navbar", {
            attrs: { balance: _vm.balance, user: _vm.user },
            on: { togglemenu: _vm.togglemenu }
          })
        ],
        1
      ),
      _c(
        "v-main",
        { staticClass: "grey darken-4" },
        [
          _vm.mainLoader
            ? _c("div", { staticClass: "mainloader" }, [_c("Default")], 1)
            : _vm._e(),
          [_c("Banner"), _c("router-view")]
        ],
        2
      ),
      _c(
        "v-footer",
        { attrs: { color: "blue-grey darken-4" } },
        [
          _c(
            "v-container",
            [
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { col: "12" } }, [
                    _c(
                      "span",
                      { staticClass: "blue-grey--text text--lighten-4" },
                      [_vm._v("Emara Play")]
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }