// const gameImageDefault = 

export default {
  lobby(lobby) {
    let banner = lobby.ban ? lobby.ban.map(banner => ({
      description: banner.d,
      imageD: banner.imgd,
      imageM: banner.imgm,
      title: banner.t,
      url: banner.url,
    })) : []; 

    return {
      operatorId: lobby.b.c,
      banner,
      name: lobby.n,
      providers: lobby.p.map(provider => ({
        c: provider.c,
        image: provider.i,
        name: provider.n,
        id: provider._id,
      })),
      categories: lobby.c.map(category => ({
        games: category.g.map(game => ({
          id: game._id,
          name: game.n,
          licenses: [...game.l],
          type: game.t,
          image: game.img ? game.img : require('@/assets/gamedefault.png'),
          k: game.k,
          vg: game.vg,
          fun: game.fun || false,
        })),
        icon: category.i,
        image: category.img,
        names: category.n.map(name => ({
          language: name.l,
          text: name.t,
        })),
        id: category._id,
      })),
    }
  },
  allgames(allgames) {
    return allgames.map(game => ({
      id: game._id,
      image: game.img ? game.img : require('@/assets/gamedefault.png'),
      licenses: [...game.l],
      name: game.n,
      type: game.t,
      k: game.k,
      vg: game.vg,
    }))
  }
}