var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-card", { staticClass: "card-game-search" }, [
    _c(
      "div",
      { staticClass: "card-game-search__img" },
      [_c("v-img", { attrs: { height: "130px", src: _vm.image } })],
      1
    ),
    _c("div", { staticClass: "card-game-search__content" }, [
      _c("div", { staticClass: "card-game-search__title" }, [
        _vm._v(_vm._s(_vm.title))
      ]),
      _c("div", { staticClass: "card-game-search__subtitle" }, [
        _vm._v(_vm._s(_vm.subtitle))
      ]),
      _c(
        "div",
        { staticClass: "card-game-search__actions" },
        [
          _c(
            "v-btn",
            {
              attrs: {
                color: "primary",
                href: _vm.url,
                small: "",
                target: "_blank"
              }
            },
            [_vm._v(_vm._s(_vm.translations.play))]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }