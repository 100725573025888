<template lang="pug">
div.default
  v-img.default__logo(
    :src="logo",
    alt="Emara play",
    position="center center",
    contain
  )
</template>

<script>
import logo from "@/assets/logo_emara.png";

export default {
  name: "Default",
  data: () => ({
    logo,
  }),
};
</script>

<style lang="sass">
.default
  height: calc(100% - 250px)
  display: flex
  align-items: center
  justify-content: center
  padding: 2rem
  &__logo
    max-width: 48rem
</style>
