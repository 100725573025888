var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      staticClass: "navbar-login",
      attrs: {
        "close-on-content-click": false,
        "offset-y": "",
        bottom: "",
        left: ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _vm.$vuetify.breakpoint.smAndDown
                ? _c(
                    "div",
                    { staticClass: "navbar-login__activator" },
                    [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            attrs: { color: "primary", outlined: "", small: "" }
                          },
                          on
                        ),
                        [_vm._v("Login / Register")]
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    { staticClass: "navbar-login__activator" },
                    [
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            attrs: { color: "primary", outlined: "", small: "" }
                          },
                          on
                        ),
                        [_vm._v("Login")]
                      ),
                      _c(
                        "v-btn",
                        _vm._g(
                          {
                            staticClass: "ml-3",
                            attrs: { color: "primary", small: "" }
                          },
                          on
                        ),
                        [_vm._v("Register")]
                      )
                    ],
                    1
                  )
            ]
          }
        }
      ]),
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c(
        "v-card",
        {
          staticClass: "navbar-login__card pa-4",
          attrs: { color: "blue-grey darken-3", elevation: 4 }
        },
        [
          _c(
            "v-form",
            {
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.handleSubmit()
                }
              },
              model: {
                value: _vm.valid,
                callback: function($$v) {
                  _vm.valid = $$v
                },
                expression: "valid"
              }
            },
            [
              _c(
                "div",
                { staticClass: "container pa-0" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("components.NavbarLogin.username"),
                              "hide-details": "",
                              solo: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.username,
                              callback: function($$v) {
                                _vm.username = $$v
                              },
                              expression: "username"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c("v-text-field", {
                            attrs: {
                              label: _vm.$t("components.NavbarLogin.password"),
                              type: "password",
                              "hide-details": "",
                              solo: "",
                              dense: ""
                            },
                            model: {
                              value: _vm.password,
                              callback: function($$v) {
                                _vm.password = $$v
                              },
                              expression: "password"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                color: "primary",
                                type: "submit",
                                outlined: "",
                                small: ""
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("components.NavbarLogin.buttonSend")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }