import { componentsEn } from './componentsEn';
import { componentsEs } from './componentsEs';
import { viewsEn } from './viewsEn';
import { viewsEs } from './viewsEs';

export const messages = {
    en: {
      components: componentsEn,
      views: viewsEn,
    },
    es: {
        components: componentsEs,
        views: viewsEs,
    }
  };