<template lang="pug">
v-card.card-game-search
  .card-game-search__img
    v-img(
      height="130px",
      :src="image")
  .card-game-search__content
    .card-game-search__title {{ title }}
    .card-game-search__subtitle {{ subtitle }}
    .card-game-search__actions
      v-btn(color="primary" :href="url" small target="_blank") {{ translations.play }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CardGameSearch',
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    image: {
      type: String,
    },
    mode: {
      type: String,
    },
    gameId: {
      type: String,
    },
    gameName: {
      type: String
    }
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState(['token', 'operatorId']),
    translations() {
      return this.$t('components.Games')
    },
    url() {
      let channel = this.isLower750 ? 'desktop' : 'mobile'
      if (this.mode === 'fun') {
        return `${process.env.VUE_APP_GAME_URL}/casino/vivo/game?operator=${this.operatorId}&gameid=${this.gameId}&mode=fun&lang=es&lobby=${location.href}&channel=${channel}`
      } else {
        return `${process.env.VUE_APP_GAME_URL}/casino/vivo/game?operator=${this.operatorId}&token=${this.token}&gameid=${this.gameId}&mode=${this.mode}&lang=es&lobby=${location.href}&channel=${channel}`
      }
    },
    isLower750(){
      return this.$vuetify.breakpoint.width < 750
    },
  },
};
</script>

<style lang="sass">
.card-game-search
  &.v-card
    width: 100%
    max-width: 350px
    display: flex
    overflow: hidden
  &__img
    flex: 0 0 50%
    max-width: 50%
  &__content
    flex: 0 0 50%
    max-width: 50%
  &__title
    color: #fff
    font-size: 16px
    padding: 6px 12px
  &__subtitle
    color: #fff
    font-size: 14px
    padding: 0 12px 6px
  &__actions
    display: flex
    justify-content: center
</style>