import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
     path: '/lobby/:lobbyId/provider',
     name: 'Provider',
     component: () => import('../views/Provider.vue')
  },
  {
    path: '/lobby/:lobbyId/category',
    name: 'CategoryGames',
    component: () => import('../views/CategoryGames.vue')
  },
  {
    path: '/lobby/:lobbyId',
    name: 'Home',
    component: Home
  },
  {
    path: '/lobby/:lobbyId/allgames',
    name: 'AllGames',
    component: () => import('../views/AllGames.vue')
  },
  {
    path: '/*',
    name: 'Default',
    component: () => import('../views/Default.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
