<template lang="pug">
v-menu(
  v-model="open",
  close-on-click,
  close-on-content-click,
  offset-y,
  bottom,
  left
)
  template(v-slot:activator="{ on }")
    v-btn(color="primary", v-on="on", icon, tile)
      v-icon(size="24", color="primary") mdi-translate
  v-list
    v-list-item.pa-0(v-for="(lang, i) in languages", :key="i")
      v-list-item-title
        v-btn(@click="setLanguage(lang.value)" text tile block) {{ lang.text }}
</template>

<script>
export default {
  name: 'ButtonLanguage',
  data: () => ({
    open: false,
    languages: [
      {
        text: 'English',
        value: 'en',
      },
      {
        text: 'Español',
        value: 'es',
      },
    ],
  }),
  methods: {
    setLanguage(val) {
      this.$i18n.locale = val;
    },
  },
}
</script>

<style lang="sass"></style>