<template lang="pug">
v-menu.navbar-login(
  v-model="open",
  :close-on-content-click="false",
  offset-y,
  bottom,
  left
)
  template(v-slot:activator="{ on }")
    .navbar-login__activator(v-if="$vuetify.breakpoint.smAndDown")
      v-btn(color="primary", v-on="on" outlined, small) Login / Register
    .navbar-login__activator(v-else)
      v-btn(color="primary", v-on="on" outlined, small) Login
      v-btn.ml-3(color="primary", v-on="on" small) Register
  v-card.navbar-login__card.pa-4(color="blue-grey darken-3" :elevation="4")
    v-form(v-model="valid" @submit.prevent="handleSubmit()")
      .container.pa-0
        v-row
          v-col
            v-text-field(
              v-model="username",
              :label="$t('components.NavbarLogin.username')",
              hide-details,
              solo,
              dense
            )
        v-row
          v-col
            v-text-field(
              v-model="password",
              :label="$t('components.NavbarLogin.password')",
              type="password",
              hide-details,
              solo,
              dense
            )
        v-row
          v-col
            v-btn(color="primary", type="submit", outlined, small) {{ $t('components.NavbarLogin.buttonSend') }}
</template>

<script>
export default {
  name: "NavbarLogin",
  props: {},
  data: () => ({
    open: false,
    username: null,
    password: null,
    valid: null,
  }),
  methods:{
    handleSubmit(){
      console.log({
        username: this.username,
        password: this.password,
      })
    },
  }
};
</script>

<style lang="sass"></style>