var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-click": "",
        "close-on-content-click": "",
        "offset-y": "",
        bottom: "",
        left: ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { color: "primary", icon: "", tile: "" } }, on),
                [
                  _c("v-icon", { attrs: { size: "24", color: "primary" } }, [
                    _vm._v("mdi-translate")
                  ])
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.open,
        callback: function($$v) {
          _vm.open = $$v
        },
        expression: "open"
      }
    },
    [
      _c(
        "v-list",
        _vm._l(_vm.languages, function(lang, i) {
          return _c(
            "v-list-item",
            { key: i, staticClass: "pa-0" },
            [
              _c(
                "v-list-item-title",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { text: "", tile: "", block: "" },
                      on: {
                        click: function($event) {
                          return _vm.setLanguage(lang.value)
                        }
                      }
                    },
                    [_vm._v(_vm._s(lang.text))]
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }