import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'

// import dbjson from './fake_db.json'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    mainLoader: true,
    lobbyId: undefined,
    token: undefined,
    operatorId: undefined,
    provider: undefined,
    categories: undefined,
    categoryId: undefined,
    bannersD: undefined,
    bannersM: undefined,
    user: undefined,
  },
  mutations: {
    setMainLoader(state, newValue){
      state.mainLoader = newValue
    },
    setLobbyId(state, lobbyId){
      state.lobbyId = lobbyId
    },
    setToken(state, token){
      state.token = token
    },
    setOperatorId(state, operatorId){
      state.operatorId = operatorId
    },
    setProvider(state, provider){
      state.provider = provider
    },
    setCategories(state, categories){
      state.categories = categories
    },
    setCategorySelected(state, categoryId){
      state.categoryId = categoryId
    },
    setBannersD(state, bannersD){
      state.bannersD = bannersD
    },
    setBannersM(state, bannersM){
      state.bannersM = bannersM
    },
    setUser(state, user){
      state.user = user
    },
  },
  actions: {
    setMainLoader({commit}, newValue) {
      commit('setMainLoader', newValue)
    },
    setLobbyId({commit}, lobbyId) {
      commit('setLobbyId', lobbyId)
      localStorage.setItem('lobbyid', lobbyId);
    },
    checkLobbyId({commit}) {
      const lobbyId = localStorage.getItem('lobbyid');
      if (lobbyId) {
        commit('setLobbyId', lobbyId)
      }
    },
    setToken({commit}, token) {
      commit('setToken', token)
      localStorage.setItem('token', token);
    },
    checkToken({commit}) {
      const token = localStorage.getItem('token');
      if (token) {
        commit('setToken', token)
      }
    },
    setOperatorId({commit}, operatorId) {
      commit('setOperatorId', operatorId)
      localStorage.setItem('operator', operatorId);
    },
    checkOperator({commit}) {
      const operatorId = localStorage.getItem('operator');
      if (operatorId) {
        commit('setOperatorId', operatorId)
      }
    },
    setProvider({commit}, provider) {
      commit('setProvider', provider)
    },
    setCategories({commit}, categories) {
      commit('setCategories', categories)
    },
    setCategorySelected({commit}, categoryId) {
      commit('setCategorySelected', categoryId)
    },
    setBannersD({commit}, bannersD) {
      commit('setBannersD', bannersD)
    },
    setBannersM({commit}, bannersM) {
      commit('setBannersM', bannersM)
    },
    setUser({commit}, user) {
      commit('setUser', user)
      localStorage.setItem('user', user);
    },
  },
  modules: {
  }
})
