import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueI18n from 'vue-i18n'
import { messages } from './lang/translations'
import './styles/styles.sass'
import vuetify from './plugins/vuetify'
import CurrencySymbol from 'vue-currency-symbol/dist/vue-currency-symbol.esm.js'

Vue.config.productionTip = false

Vue.use(VueI18n)
Vue.use(CurrencySymbol)

// I18n
const i18n = new VueI18n({
  locale: 'es',
  messages,
})

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
