var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { staticClass: "card-game rounded-lg" },
    [
      _c(
        "v-img",
        {
          staticClass: "white--text align-end",
          attrs: { "aspect-ratio": 1 / 1, src: _vm.image }
        },
        [
          _c("div", { staticClass: "card-game__overlay" }, [
            _c("div", { staticClass: "card-game__title" }, [
              _vm._v(_vm._s(_vm.title))
            ]),
            _c("div", { staticClass: "card-game__subtitle" }, [
              _vm._v(_vm._s(_vm.subtitle))
            ])
          ]),
          _vm.canShowPlay
            ? _c(
                "v-card-actions",
                { staticClass: "card-game__actions" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        href: _vm.url,
                        target: "_blank"
                      }
                    },
                    [_vm._v(_vm._s(_vm.translations.play))]
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }