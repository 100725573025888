var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "slider" }, [
    !_vm.hideTitle
      ? _c("div", { staticClass: "slider__top" }, [
          _c("h2", { staticClass: "slider__title" }, [_vm._t("title")], 2),
          _vm.hasScroll && !_vm.hideControls
            ? _c("div", { staticClass: "slider__navigation" }, [
                _c(
                  "button",
                  {
                    staticClass: "slider__button slider__prev",
                    class: { "slider__button--disabled": !_vm.activePrev },
                    on: { click: _vm.movePrev }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          color: _vm.activePrev ? "primary" : "grey",
                          large: ""
                        }
                      },
                      [_vm._v("mdi-chevron-left")]
                    )
                  ],
                  1
                ),
                _c(
                  "button",
                  {
                    staticClass: "slider__button slider__next",
                    class: { "slider__button--disabled": !_vm.activeNext },
                    on: { click: _vm.moveNext }
                  },
                  [
                    _c(
                      "v-icon",
                      {
                        attrs: {
                          color: _vm.activeNext ? "primary" : "grey",
                          large: ""
                        }
                      },
                      [_vm._v("mdi-chevron-right")]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _c("div", { staticClass: "slider__wrapper" }, [
      _c(
        "div",
        {
          staticClass: "slider__content",
          on: { scroll: _vm.updateScrollPosition }
        },
        [
          _c(
            "ul",
            { staticClass: "slider__list" },
            [
              _vm.$slots.first
                ? _c(
                    "li",
                    { staticClass: "slider__item" },
                    [_vm._t("first")],
                    2
                  )
                : _vm._e(),
              _vm._l(_vm.items, function(item, ind) {
                return _c(
                  "li",
                  {
                    key: ind,
                    staticClass: "slider__item",
                    class: { "slider__item--fixed": _vm.widthfixed }
                  },
                  [_vm._t("default", null, { item: item })],
                  2
                )
              })
            ],
            2
          )
        ]
      ),
      _vm.controlLateral && _vm.hasScroll && !_vm.hideControls
        ? _c("div", { staticClass: "slider__navigation" }, [
            _c(
              "button",
              {
                staticClass: "slider__button slider__prev",
                class: { "slider__button--disabled": !_vm.activePrev },
                on: { click: _vm.movePrev }
              },
              [
                _c(
                  "v-icon",
                  {
                    attrs: {
                      color: _vm.activePrev ? "primary" : "grey",
                      large: ""
                    }
                  },
                  [_vm._v("mdi-chevron-left")]
                )
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass: "slider__button slider__next",
                class: { "slider__button--disabled": !_vm.activeNext },
                on: { click: _vm.moveNext }
              },
              [
                _c(
                  "v-icon",
                  {
                    attrs: {
                      color: _vm.activeNext ? "primary" : "grey",
                      large: ""
                    }
                  },
                  [_vm._v("mdi-chevron-right")]
                )
              ],
              1
            )
          ])
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }