import axios from 'axios'
import Normalize from './normalize'

export default {
  async getLobby(lobbyId) {
    return axios.get(`${process.env.VUE_APP_SERVER}/lobby/${lobbyId}`)
      .then((res) => {
        return Normalize.lobby(res.data)
      })
      .catch((error) => {
          console.log(error)
      })
  }
}