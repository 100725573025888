<template lang="pug">
  v-app(dark)
    //- v-navigation-drawer(
    //-   v-model="menu"
    //-   absolute
    //-   temporary)
    //-   v-list(nav dense)
    //-     v-list-item-group(v-model="group" active-class="deep-purple--text text--accent-4")
    //-       v-list-item
    //-         v-list-item-title
    //-           router-link.drawer__link(:to="{ name: 'Home', params: { lobbyId: lobbyId }}") Home
    //-       v-list-item
    //-         v-list-item-title
    //-           router-link.drawer__link(:to="{ name: 'AllGames', params: { lobbyId: lobbyId }}") Juegos
    v-app-bar(elevate-on-scroll fixed app color="blue-grey darken-4")
      Navbar(@togglemenu="togglemenu" :balance="balance" :user="user")
    v-main(class="grey darken-4")
      div.mainloader(v-if="mainLoader")
        Default
      template(v-show="!mainLoader")
        Banner
        router-view
    v-footer(color="blue-grey darken-4")
      v-container
        v-row
          v-col(col="12")
            span.blue-grey--text.text--lighten-4 Emara Play
</template>

<script>
import { mapActions, mapState } from "vuex"
import Navbar from '@/components/Navbar'
import Banner from '@/components/Banner'
import Default from '@/views/Default'
import BalanceServices from '@/services/BalanceServices'

export default {
  name: 'App',
  components: {
    Navbar,
    Banner,
    Default,
  },
  data: ()=>({
    menu: false,
    group: null,
    token: null,
    balance: null,
    user: null,
  }),
  watch: {
    token: function (val) {
      if(val) {
        //pendiente de hacer algo con el balance y de ver cómo funciona
        BalanceServices.getBalance(this.lobbyId, val, this.user, this.operatorId).then(balance => {
          this.balance = balance
        })
      } else {
        this.balance = null
      }
    },
  },
  created() {
    const lobbyId = this.$route.params.lobbyId
    const token = this.$route.query.token
    const user = this.$route.query.user
    const operatorId = this.$route.query.operator
    if (lobbyId) {
      this.setLobbyId(lobbyId)
    }
    if (token) {
      this.setToken(token)
      this.token = token
    }
    if (operatorId) {
      this.setOperatorId(operatorId)
    }
    if (user) {
      this.setUser(user)
      this.user = user;
    }
    this.checkLobbyId()
    this.checkToken()
    this.checkOperator()
  },
  computed: {
    ...mapState(['lobbyId', 'mainLoader', 'operatorId']),
  },
  methods: {
    ...mapActions(['checkLobbyId', 'setLobbyId', 'setToken', 'checkToken', 'setOperatorId', 'checkOperator', 'setUser']),
    togglemenu(){
      this.menu = !this.menu
    }
  }
};
</script>

<style lang="sass">
.drawer__link
  align-items: center
  display: flex
  text-decoration: none
  min-height: 40px
  &:hover, &:focus
    text-decoration: underline
.mainloader
  height: 100%
</style>
