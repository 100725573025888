var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showOnDesktop
    ? _c(
        "v-carousel",
        {
          staticClass: "banner",
          attrs: {
            height: "250",
            cycle: "",
            interval: "6000",
            "show-arrows": false,
            "hide-delimiter-background": ""
          }
        },
        _vm._l(_vm.bannersD, function(item, i) {
          return _c(
            "v-carousel-item",
            { key: i, attrs: { src: item.imageD } },
            [
              _c(
                "v-sheet",
                {
                  staticClass: "banner__sheet",
                  attrs: { height: "100%", tile: "" }
                },
                [
                  _c(
                    "a",
                    { staticClass: "banner__link", attrs: { href: item.url } },
                    [
                      _c("span", { staticClass: "text-h4" }, [
                        _vm._v(_vm._s(item.title))
                      ]),
                      _c("span", { staticClass: "text-h5" }, [
                        _vm._v(_vm._s(item.description))
                      ])
                    ]
                  )
                ]
              )
            ],
            1
          )
        }),
        1
      )
    : _vm.showOnMobile
    ? _c(
        "v-carousel",
        {
          staticClass: "banner",
          attrs: {
            height: "250",
            cycle: "",
            interval: "6000",
            "show-arrows": false,
            "hide-delimiter-background": ""
          }
        },
        _vm._l(_vm.bannersM, function(item, i) {
          return _c(
            "v-carousel-item",
            { key: i, attrs: { src: item.imageM } },
            [
              _c(
                "v-sheet",
                {
                  staticClass: "banner__sheet",
                  attrs: { height: "100%", tile: "" }
                },
                [
                  _c(
                    "a",
                    { staticClass: "banner__link", attrs: { href: item.url } },
                    [
                      _c("span", { staticClass: "text-h4" }, [
                        _vm._v(_vm._s(item.title))
                      ]),
                      _c("span", { staticClass: "text-h5" }, [
                        _vm._v(_vm._s(item.description))
                      ])
                    ]
                  )
                ]
              )
            ],
            1
          )
        }),
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }