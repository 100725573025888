var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-container", { staticClass: "navbar py-0" }, [
    _vm.$vuetify.breakpoint.smAndDown
      ? _c(
          "div",
          { staticClass: "navbar__grid" },
          [
            _c("v-img", {
              staticClass: "navbar__logo",
              attrs: {
                src: _vm.logo,
                alt: "Emara play",
                position: "center center",
                contain: ""
              },
              on: { click: _vm.goToHome }
            }),
            _c(
              "div",
              { staticClass: "navbar__user" },
              [
                _vm.balance
                  ? [
                      _c("div", { staticClass: "navbar__balance" }, [
                        _c("div", [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$t("components.NavbarLogin.balance"))
                            )
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.balance.result.balance) + " "),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.currencySymbol(
                                    _vm.balance.result.currency
                                  )
                                )
                              }
                            })
                          ])
                        ])
                      ])
                    ]
                  : [_c("NavbarLogin")],
                _c("ButtonLanguage")
              ],
              2
            )
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "navbar__grid" },
          [
            _c("v-img", {
              staticClass: "navbar__logo",
              attrs: {
                src: _vm.logo,
                alt: "Emara play",
                position: "left center",
                contain: ""
              },
              on: { click: _vm.goToHome }
            }),
            _c("div", { staticClass: "navbar__links" }),
            _c(
              "div",
              { staticClass: "navbar__user" },
              [
                _vm.balance
                  ? [
                      _c("div", { staticClass: "navbar__balance" }, [
                        _c("div", [
                          _c("div", [
                            _vm._v(
                              _vm._s(_vm.$t("components.NavbarLogin.balance"))
                            )
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.balance.result.balance) + " "),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.currencySymbol(
                                    _vm.balance.result.currency
                                  )
                                )
                              }
                            })
                          ])
                        ])
                      ])
                    ]
                  : [_c("NavbarLogin")],
                _c("ButtonLanguage")
              ],
              2
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }