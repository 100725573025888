<template lang="pug">
.slider
  .slider__top(v-if="!hideTitle")
    h2.slider__title
      slot(name="title")
    .slider__navigation(v-if="hasScroll && !hideControls")
      button.slider__button.slider__prev(:class="{'slider__button--disabled': !activePrev}" @click="movePrev")
        v-icon(:color="activePrev ? 'primary' : 'grey'" large) mdi-chevron-left
      button.slider__button.slider__next(:class="{'slider__button--disabled': !activeNext}" @click="moveNext")
        v-icon(:color="activeNext ? 'primary' : 'grey'" large) mdi-chevron-right
  .slider__wrapper
    .slider__content(@scroll="updateScrollPosition")
      ul.slider__list
        li.slider__item(v-if="$slots.first")
          slot(name="first")
        li.slider__item(v-for="item, ind in items" :key="ind" :class="{'slider__item--fixed':widthfixed}")
          slot(:item="item")
    .slider__navigation(v-if="controlLateral && hasScroll && !hideControls")
      button.slider__button.slider__prev(:class="{'slider__button--disabled': !activePrev}" @click="movePrev")
        v-icon(:color="activePrev ? 'primary' : 'grey'" large) mdi-chevron-left
      button.slider__button.slider__next(:class="{'slider__button--disabled': !activeNext}" @click="moveNext")
        v-icon(:color="activeNext ? 'primary' : 'grey'" large) mdi-chevron-right
</template>

<script>

export default {
  name: "Slider",
  props: {
    items: {
      type: Array,
    },
    hideTitle: {
      type: Boolean,
      default: false,
    },
    controlLateral: {
      type: Boolean,
      default: false,
    },
    hideControls: {
      type: Boolean,
      default: false,
    },
    widthfixed: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hasScroll: false,
    activePrev: false,
    activeNext: false,
    scrollElement: null,
  }),
  mounted() {
    this.scrollElement = this.$el.querySelector('.slider__content')
    this.isVisibleScroll()
    window.addEventListener('resize', this.isVisibleScroll)
  },
  updated() {
    this.isVisibleScroll()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.isVisibleScroll)
  },
  methods: {
    isVisibleScroll() {
      this.hasScroll = this.scrollElement.clientWidth < this.scrollElement.scrollWidth
      this.updateScrollPosition()
    },
    movePrev() {
      this.scrollElement.scroll({
        left: this.scrollElement.scrollLeft - (this.scrollElement.clientWidth / 2),
        behavior: 'smooth'
      })
    },
    moveNext() {
      this.scrollElement.scroll({
        left: this.scrollElement.scrollLeft + (this.scrollElement.clientWidth / 2),
        behavior: 'smooth'
      })
    },
    updateScrollPosition() {
      this.activePrev = this.scrollElement.scrollLeft !== 0
      this.activeNext = Math.ceil(this.scrollElement.scrollLeft + this.scrollElement.clientWidth) !== this.scrollElement.scrollWidth
    },
  }
};
</script>

<style lang="sass">
@import '@/styles/_variables.sass'

.slider
  &__top
    display: flex
    align-items: center
    justify-content: space-between
  &__title
    padding-top: 8px
    padding-bottom: 16px
  &__wrapper
    display: flex
  &__navigation
    flex-shrink: 0
  &__content
    flex: 1 1 auto
    overflow: auto
    &::-webkit-scrollbar
      height: 0
      width: 0
      opacity: 0
    &--reserved
      padding
  &__content &__list
    list-style: none
    padding: 0
    margin: 0
    display: flex
    // justify-content: space-between
  &__item
    flex-shrink: 0
    & + &
      margin-left: 1rem
    &--fixed
      flex: 0 0 calc((100%/2) - 1rem)
      min-width: calc((100%/2) - 1rem)
      @media (min-width: 600px)
        flex: 0 0 calc((100%/3) - (2rem/3))
        min-width: calc((100%/3) - (2rem/3))
      @media (min-width: 750px)
        flex: 0 0 calc((100%/4) - (3rem/4))
        min-width: calc((100%/4) - (3rem/4))
      @media (min-width: 900px)
        flex: 0 0 calc((100%/5) - (4rem/5))
        min-width: calc((100%/5) - (4rem/5))
  &__button
    padding: 0
    box-shadow: inset 0 0 0 1px $primary
    &--disabled
      box-shadow: inset 0 0 0 1px #9e9e9e
  &__prev
    border-radius: 2rem 0 0 2rem
  &__next
    border-radius: 0 2rem 2rem 0

</style>