<template lang="pug">
v-card.card-game.rounded-lg
  v-img.white--text.align-end(
    :aspect-ratio="1/1"
    :src="image")
    .card-game__overlay
      .card-game__title {{ title }}
      .card-game__subtitle {{ subtitle }}
    v-card-actions.card-game__actions(v-if="canShowPlay")
      v-btn(color="primary" :href="url" target="_blank") {{ translations.play }}
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CardGame',
  props: {
    title: {
      type: String,
    },
    subtitle: {
      type: String,
    },
    image: {
      type: String,
    },
    fun: {
      type: Boolean,
    },
    gameId: {
      type: String,
    },
    gameName: {
      type: String
    }
  },
  data: () => ({
    dialog: false,
  }),
  computed: {
    ...mapState(['token', 'operatorId']),
    translations() {
      return this.$t('components.Games')
    },
    canShowPlay() {
      return this.token || this.fun;
    },
    url() {
      let channel = this.isLower750 ? 'desktop' : 'mobile'
      let url = `${process.env.VUE_APP_GAME_URL}/casino/vivo/game?operator=${this.operatorId}&gameid=${this.gameId}&lang=es&lobby=${encodeURIComponent(location.href)}&channel=${channel}`;
      if (!this.token) {
        url += '&mode=fun';
      } else {
        url += `&token=${this.token}&mode=real`;
      }
      return url;
    },
    isLower750(){
      return this.$vuetify.breakpoint.width < 750
    },
  },
};
</script>

<style lang="sass">
@import '@/styles/_variables.sass'

.card-game
  &__overlay
    background-image: linear-gradient(rgba(#000,0) 0%, rgba(#000,.7) 50%)
    padding-top: 2rem
  &__title
    color: #fff
    font-size: 1.25rem
    font-weight: 600
    line-height: 1.5rem
    padding: 1rem
  &__subtitle
    color: #ccc
    font-size: 0.875rem
    padding: 0 1rem 1rem
  &__actions
    background-color: rgba(#37474f, 0.7)
    position: absolute
    top: 0
    left: 0
    height: 100%
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    transform: translateY(100%)
    transition: transform .15s ease-in-out
  &:hover &__actions, &:focus &__actions, &:focus-within &__actions
    transform: translateY(0%)
  &__dialog.theme--dark.v-sheet--outlined
    border: thin solid hsla(0,0%,100%,.2)
</style>