var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("GroupCategories", { attrs: { categories: _vm.categories } }),
      _c("v-container", [
        _c(
          "ol",
          { staticClass: "list_clear home", attrs: { dark: "" } },
          [
            _c(
              "li",
              [
                _c("Slider", {
                  attrs: { items: _vm.providers, hideControls: _vm.isLower750 },
                  scopedSlots: _vm._u([
                    {
                      key: "title",
                      fn: function() {
                        return [
                          _c(
                            "v-chip",
                            {
                              staticClass: "pa-1 mr-3",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                label: ""
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { attrs: { size: "24", color: "primary" } },
                                [_vm._v("mdi-google-controller")]
                              )
                            ],
                            1
                          ),
                          _vm._v(_vm._s(_vm.translations.providers))
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(ref) {
                        var item = ref.item
                        return [
                          _c(
                            "v-btn",
                            {
                              attrs: { tile: "", "x-large": "" },
                              on: {
                                click: function($event) {
                                  return _vm.goToProvider(
                                    item.id,
                                    item.name,
                                    item.image
                                  )
                                }
                              }
                            },
                            [
                              _c("v-img", {
                                staticClass: "provider__img",
                                attrs: { src: item.image, alt: item.name }
                              }),
                              _c("span", [_vm._v(_vm._s(item.name))])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._l(_vm.categories, function(category) {
              return _c(
                "li",
                { key: category.id, staticClass: "mt-4" },
                [
                  _c("Slider", {
                    attrs: {
                      items: category.games,
                      hideControls: _vm.isLower750,
                      widthfixed: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "title",
                          fn: function() {
                            return [
                              _c(
                                "v-chip",
                                {
                                  staticClass: "pa-1",
                                  attrs: {
                                    color: "primary",
                                    outlined: "",
                                    label: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    { attrs: { size: "24", color: "primary" } },
                                    [
                                      _vm._v(
                                        "mdi-" +
                                          _vm._s(_vm.getIcon(category.icon))
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "title-link",
                                  attrs: { text: "", tile: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.goToCategory(category.id)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.nameLanguage(category.names))
                                  )
                                ]
                              )
                            ]
                          },
                          proxy: true
                        },
                        {
                          key: "default",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _c("CardGame", {
                                attrs: {
                                  title: item.name,
                                  subtitle: item.type,
                                  image: item.image,
                                  gameId: item.vg,
                                  fun: item.fun
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              )
            })
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }