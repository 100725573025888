<template lang="pug">
v-sheet.mt-4(color="blue-grey darken-4")
  v-container
    .float-right
      Search
    v-row
      v-col
        Slider(:items="categories" hideTitle hideControls)
          template(v-slot:first)
            v-btn(text tile @click="goToAllgames") {{ translations.AllGames }}
          template(v-slot="{item}")
            v-btn(text tile @click="goToCategory(item.id)") {{ nameLanguage(item.names) }}
</template>

<script>
import { mapState, mapActions } from "vuex"
import Slider from "@/components/Slider"
import Search from "@/components/Search"

export default {
  name: 'GroupCategories',
  components: {
    Slider,
    Search,
  },
  props: {
    categories: {
      type: Array,
    },
  },
  data: () => ({
    open: false,
  }),
  computed: {
    ...mapState(['lobbyId']),
    translations() {
      return this.$t('components.GroupCategories')
    },
  },
  methods: {
    ...mapActions(['setCategorySelected']),
    nameLanguage(nameList) {
      let nameToShow = ''
      if (nameList) {
        if (nameList.length === 1) {
          nameToShow = nameList[0].text
        } else {
          const candidate = nameList.find(name => this.$i18n.locale.localeCompare(name.language.toLowerCase()) === 0 )
          if (candidate) {
            nameToShow = candidate.text
          }
        }
      }
      return nameToShow
    },
    goToCategory(cartegoryId) {
      this.setCategorySelected(cartegoryId)
      if(this.$router.currentRoute.name !== 'CategoryGames') {
        this.$router.push({name:'CategoryGames', params: { lobbyId: this.lobbyId }})
      }
    },
    goToAllgames() {
      if(this.$router.currentRoute.name !== 'AllGames') {
        this.$router.push({ name: 'AllGames', params: { lobbyId: this.lobbyId }})
      }
    },
  }
}
</script>

<style lang="sass"></style>