<template lang="pug">
div
  //- Listado de categorías
  GroupCategories(:categories="categories")

  v-container
    ol.list_clear.home(dark)
      //- Listado de proveedores
      li
        Slider(:items="providers" :hideControls="isLower750")
          template(v-slot:title)
            v-chip.pa-1.mr-3(color="primary", outlined, label)
              v-icon(size="24", color="primary") mdi-google-controller
            | {{ translations.providers }}
          template(v-slot="{item}")
            v-btn(tile x-large @click="goToProvider(item.id, item.name, item.image)")
              v-img.provider__img(
                :src="item.image",
                :alt="item.name")
              span {{ item.name }}

      //- Listado de juegos por categorías
      li.mt-4(v-for="category in categories" :key="category.id")
        Slider(:items="category.games" :hideControls="isLower750" widthfixed)
          template(v-slot:title)
            v-chip.pa-1(color="primary", outlined, label)
              v-icon(size="24", color="primary") mdi-{{ getIcon(category.icon) }}
            v-btn.title-link(text tile @click="goToCategory(category.id)") {{ nameLanguage(category.names) }}
          template(v-slot="{item}")
            CardGame(
              :title="item.name",
              :subtitle="item.type",
              :image="item.image"
              :gameId="item.vg"
              :fun="item.fun")
</template>

<script>
import { mapState, mapActions } from 'vuex'
import CardGame from '@/components/CardGame'

import Slider from '@/components/Slider'
import GroupCategories from '@/components/GroupCategories'

import LobbyServices from '@/services/LobbyServices'

export default {
  name: 'Home',
  components: {
    CardGame,
    GroupCategories,
    Slider,
  },
  data: () => ({
    name: undefined,
    banner: undefined,
    categories: [],
    providers: [],
  }),
  computed: {
    ...mapState(['lobbyId']),
    translations() {
      return this.$t('views.Home')
    },
    isLower750(){
      return this.$vuetify.breakpoint.width < 750
    },
  },
  created() {
    LobbyServices.getLobby(this.lobbyId).then(lobby => {
      this.setMainLoader(false)
      this.getBanner(lobby.banner)
      this.setOperatorId(lobby.operatorId)
      this.name = lobby.name
      this.banner = lobby.banner
      this.categories = [...lobby.categories]
      this.providers = [...lobby.providers]
      this.setCategories(this.categories)
    })
  },
  methods: {
    ...mapActions(['setMainLoader', 'setProvider', 'setUser', 'setOperatorId', 'setCategories', 'setCategorySelected', 'setBannersD', 'setBannersM']),
    nameLanguage(nameList) {
      let nameToShow = ''
      if (nameList) {
        if (nameList.length === 1) {
          nameToShow = nameList[0].text
        } else {
          const candidate = nameList.find(name => this.$i18n.locale.localeCompare(name.language.toLowerCase()) === 0 )
          if (candidate) {
            nameToShow = candidate.text
          }
        }
      }
      return nameToShow
    },
    getIcon(icon) {
      return icon && icon.length > 1 ? icon : 'billiards'
    },
    goToProvider(providerId, providerName, providerImage) {
      this.setProvider({id: providerId, name: providerName, image: providerImage})
      this.$router.push({name:'Provider', params: { lobbyId: this.lobbyId }})
    },
    goToCategory(cartegoryId) {
      this.setCategorySelected(cartegoryId)
      this.$router.push({name:'CategoryGames', params: { lobbyId: this.lobbyId }})
    },
    getBanner(banner){
      const imageDesktopList = banner.filter(item => {
        return item.imageD != undefined && item.imageD != ''
      })
      const imageMobileList = banner.filter(item => {
        return item.imageM != undefined && item.imageM != ''
      })
      this.setBannersD(imageDesktopList)
      this.setBannersM(imageMobileList)
    },
  },
};
</script>

<style lang="sass">
@import '@/styles/_variables.sass'

.title-link
  font-size: 1.2rem
  text-decoration: none
  &:hover, &:focus
    text-decoration: underline
  &.v-btn.theme--dark
    text-transform: none    //important o clase
    color: $primary
    font-size: 1.2rem
    font-weight: 700
    letter-spacing: 0
.provider__img
  height: 28px
  width: 28px
  margin-right: 12px
</style>