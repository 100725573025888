export const componentsEs = {
  NavbarLogin: {
    username: 'Nombre de usuario',
    password: 'Contraseña',
    buttonSend: 'Acceder',
    balance: 'Balance',
  },
  GroupCategories: {
    AllGames: 'Juegos',
  },
  Search: {
    search: 'Buscar',
    close: 'Cerrar',
    searchEmpty: 'No se han encontrado juegos',
  },
  Games: {
    play: 'Play',
  },
}