import axios from 'axios'

export default {
  async getBalance(lobbyId, token, user, operator) {
    console.log(token)
    return axios.get(`${process.env.VUE_APP_SERVER}/casino/vivo/balance?user=${user}&token=${token}&lobby=${lobbyId}&operator=${operator}`)
      .then((res) => {
        return res.data;
      })
      .catch((error) => {
        console.log(error)
      });
    // return { result: { balance: 10000 } }
  },
}