var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    { staticClass: "mt-4", attrs: { color: "blue-grey darken-4" } },
    [
      _c(
        "v-container",
        [
          _c("div", { staticClass: "float-right" }, [_c("Search")], 1),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c("Slider", {
                    attrs: {
                      items: _vm.categories,
                      hideTitle: "",
                      hideControls: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", tile: "" },
                                on: { click: _vm.goToAllgames }
                              },
                              [_vm._v(_vm._s(_vm.translations.AllGames))]
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "default",
                        fn: function(ref) {
                          var item = ref.item
                          return [
                            _c(
                              "v-btn",
                              {
                                attrs: { text: "", tile: "" },
                                on: {
                                  click: function($event) {
                                    return _vm.goToCategory(item.id)
                                  }
                                }
                              },
                              [_vm._v(_vm._s(_vm.nameLanguage(item.names)))]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }