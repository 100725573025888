<template lang="pug">
.search(v-click-outside="closeSearch")
  .search__activator
    v-tooltip(top)
      template(v-slot:activator="{ on }")
        v-btn(icon tile v-on="on" @click="toggleSearch")
          transition
            v-icon(v-if="!showSearcher" size="24", color="primary") mdi-magnify
            v-icon(v-else size="24", color="primary") mdi-close
      span(v-if="!showSearcher") {{ translations.search }}
      span(v-else) {{ translations.close }}
  .search__searcher(:class="{'search__searcher--opened' : showSearcher}")
    .search__searcher-container
      v-text-field(
        ref="searchfield"
        :label="translations.search"
        v-model="search"
        hide-details
        solo
        dense
        @keyup="searchAuto")
  .search__content(:class="{'search__content--opened' : showContent}")
    .search__scroll
      CardGameSearch.ma-2(
        v-for="game in gamesToShow"
        :key="game.id"
        :title="game.name",
        :subtitle="game.type",
        :image="game.image"
        :gameId="game.vg"
        :mode="game.mode")
      .search__empty(v-if="gamesToShow && gamesToShow.length === 0") {{ translations.searchEmpty }}
</template>

<script>
import { mapState } from "vuex"
import SearchServices from "@/services/SearchServices"
import CardGame from "@/components/CardGame"
import CardGameSearch from "@/components/CardGameSearch"

export default {
  name: "Search",
  components: {
    CardGame,
    CardGameSearch,
  },
  data: () => ({
    showSearcher: false,
    showContent: false,
    search: null,
    gamesToShow: null
  }),
  computed: {
    ...mapState(['lobbyId']),
    translations() {
      return this.$t('components.Search')
    },
  },
  methods: {
    toggleSearch() {
      this.showSearcher = !this.showSearcher
      if(!this.showSearcher) {
        this.showContent = false
        this.search = null
        this.gamesToShow = null
      } else {
        this.$refs.searchfield.focus()
      }
    },
    closeSearch() {
      this.showSearcher = false
      this.showContent = false
      this.search = null
      this.gamesToShow = null
    },
    searchAuto() {
      if(this.search.length > 2) {
        this.showContent = true
        SearchServices.search(this.lobbyId, this.search).then(games => {
          this.gamesToShow = games
        })
      }
    },
  }
};
</script>

<style lang="sass">
.search
  position: relative
  &__searcher-container
    display: flex
    opacity: 0
    transition: opacity 0.1s ease-out
  &__searcher
    position: absolute
    top: 0
    left: 0
    background-color: #37474f
    transform: translateX(-100%)
    width: 16rem
    max-width: 0
    transition: max-width 0.2s ease-out 0.1s
    overflow: hidden
    pointer-events: none
    &--opened
      max-width: 16rem
      transition: max-width 0.2s ease-out
      pointer-events: auto
      .search__searcher-container
        opacity: 1
        transition: opacity 0.1s ease-out 0.2s
  &__content
    position: absolute
    bottom: 0
    right: 0
    background-color: #37474f
    transform: translateY(100%)
    z-index: 1
    opacity: 0
    transition: opacity 0.3s ease-out
    overflow: hidden
    pointer-events: none
    width: 23.5rem
    max-width: 95vw
    &--opened
      opacity: 1
      pointer-events: auto
  &__scroll
    height: 100%
    overflow: auto
    display: flex
    flex-wrap: wrap
    max-height: 20rem
    &::-webkit-scrollbar-track
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3)
      background-color: white
    &::-webkit-scrollbar
      width: 10px
      background-color: white
    &::-webkit-scrollbar-thumb
      background-color: #F90
  &__empty
    padding: 16px
</style>