import axios from 'axios'
import Normalize from './normalize'

export default {
  async search(lobbyId, text) {
    return axios.get(`${process.env.VUE_APP_SERVER}/lobby/${lobbyId}/search?q=${text}`)
      .then((res) => {
        return Normalize.allgames(res.data)
      })
      .catch((error) => {
        console.log(error)
      });
  },
}