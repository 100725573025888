var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "default" },
    [
      _c("v-img", {
        staticClass: "default__logo",
        attrs: {
          src: _vm.logo,
          alt: "Emara play",
          position: "center center",
          contain: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }